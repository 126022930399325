// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.officerCard:hover {
  transform: scale(1.1);
}

.officerText {
  /* -webkit-text-stroke-width: 1px; */
  /* -webkit-text-stroke-color: black; */
  text-align: center;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/Officers/OfficerCard/OfficerCard.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,oCAAoC;EACpC,sCAAsC;EACtC,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".officerCard:hover {\n  transform: scale(1.1);\n}\n\n.officerText {\n  /* -webkit-text-stroke-width: 1px; */\n  /* -webkit-text-stroke-color: black; */\n  text-align: center;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

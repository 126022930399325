import React from "react";
import WeekCalendar from "react-week-calendar";
import moment from "moment";
import "./calendarStyle.css";
import "./Tutoring.scss";
import TutoringEvent from "./TutoringEvent";

function TutoringCalendar(props) {
  return (
    <div className="TutoringCalendar">
      <WeekCalendar
        scaleUnit={60}
        startTime={moment({ h: 10, m: 0 })}
        endTime={moment({ h: 16, m: 0 })}
        dayFormat="dddd"
        scaleFormat="h:mm A"
        firstDay={moment().startOf("isoWeek")}
        numberOfDays={5}
        cellHeight={80}
        selectedIntervals={props.selectedIntervals}
        eventComponent={TutoringEvent}
        eventSpacing={0}
      />
    </div>
  );
}

export default TutoringCalendar;

import "./Officers.scss";
import HEADER from "./officermain.jpg";
import OfficersData from "./OfficersData";
import { Col, Row, Container } from "react-bootstrap";
import OfficerCard from "./OfficerCard/OfficerCard";
import HEADER_IMAGE from "../f.jpg";

/*Each Officer consists of an image, position, name, and email*/
function Officers() {
  return (
    <div className="Officers">
      {/*OfficersHeader contains the picture and "OFFICER" text overlay*/}
      <div id="OfficersHeader">
        <img src={HEADER_IMAGE} id="OfficersHeaderImage" alt="Royce Hall at night" />
        <Container id="OfficersTextOverlay">
          <h1>Officers</h1>
        </Container>
      </div>
      {/*OfficersBody contains the short description and officer cards*/}
      <div className="OfficersBody">
        <Container>
          <br />
          <div className="BodyText">
            Click on each officer for more details.
          </div>
          <br />
          <Row xs={1} md={3} lg={5}>
            {OfficersData.map((officer) => (
              <Col key={officer.id}>
                <OfficerCard
                  key={officer.id}
                  name={officer.name}
                  imgPath={"/assets/OfficerImages/" + officer.src}
                  email={officer.email}
                  position={officer.position}
                  bio={officer.bio}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default Officers;

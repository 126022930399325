// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#landing-overlay {
  position: absolute;
  width: 500px;
  height: 40%;
  top: 250px;
  left: 15vw;
  color: white;
  z-index: 9;
}

#landing-carousel {
  height: 100%;
  top: 10%;
  width: 100%;
}

#landingPage {
  overflow-y: hidden;
}

@media only screen and (min-width: 900px) {
  #landing-title-small {
    font-size: 25px;
    font-family: Arial, Helvetica, sans-serif !important;
    -webkit-text-decoration: bold;
            text-decoration: bold;
    font-weight: bold;
    /* font-family: "Quicksand", sans-serif; */
  }
  #landing-title-large {
    font-size: 40px;
    font-family: Arial, Helvetica, sans-serif !important;
    -webkit-text-decoration: bold;
            text-decoration: bold;
    font-weight: bold;
    padding-bottom: 0.3rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/Landing/Landing.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EACA,UAAA;EACA,YAAA;EACA,UAAA;AADF;;AAIA;EACE,YAAA;EACA,QAAA;EACA,WAAA;AADF;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE;ICtBA,eAAA;IACA,oDAAA;IACA,6BAAA;YAAA,qBAAA;IACA,iBAAA;IDqBE,0CAAA;EAEF;EAAA;ICnBA,eAAA;IACA,oDAAA;IACA,6BAAA;YAAA,qBAAA;IACA,iBAAA;IACA,sBAAA;EDsBA;AACF","sourcesContent":["@import \"../../../mixins.scss\";\n\n#landing-overlay {\n  position: absolute;\n  width: 500px;\n  height: 40%;\n  top: 250px;\n  left: 15vw;\n  color: white;\n  z-index: 9; \n}\n\n#landing-carousel {\n  height: 100%;\n  top: 10%;\n  width: 100%;\n}\n\n#landingPage {\n  overflow-y: hidden;\n}\n\n@media only screen and (min-width: 900px) {\n  #landing-title-small {\n    @include small-heading-font;\n    /* font-family: \"Quicksand\", sans-serif; */\n  }\n  #landing-title-large {\n    @include heading-font;\n    // -webkit-text-stroke: 2px #0f2a5f;\n    // /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */\n    // /* font-family: \"Quicksand\", sans-serif; */\n    // font-size: 3.5vw;\n  }\n}\n","@mixin small-heading-font {\n  font-size: 25px;\n  font-family: Arial, Helvetica, sans-serif !important;\n  text-decoration: bold;\n  font-weight: bold;\n}\n\n@mixin heading-font {\n  font-size: 40px;\n  font-family: Arial, Helvetica, sans-serif !important;\n  text-decoration: bold;\n  font-weight: bold;\n  padding-bottom: 0.3rem;\n}\n\n@mixin title-overlay {\n  margin-top: -150px;\n  height: 150px;\n  top: 0px;\n  h1 {\n    @include heading-font;\n  }\n}\n\n@mixin image-banner {\n  width: 100%;\n  height: 300px;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

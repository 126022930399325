const OfficerData = [
    {
        "bio": "", 
        "name": "Ben Lanir", 
        "src": "Ben_Lanir.jpg", 
        "id": 0, 
        "fun": "", 
        "position": "President", 
        "email": "lanir@ucla.edu"
    }, 
    {
        "bio": "", 
        "name": "Dylan Lee", 
        "src": "Dylan_Lee.jpg", 
        "id": 1, 
        "fun": "", 
        "position": "Internal Vice President", 
        "email": "dylanakiralee@g.ucla.edu"
    }, 
    {
        "bio": "", 
        "name": "Jason Song", 
        "src": "Jason_Song.jpg", 
        "id": 2, 
        "fun": "", 
        "position": "External Vice President", 
        "email": "jason.song380@gmail.com"
    }, 
    {
        "bio": "", 
        "name": "Lillan Lamb", 
        "src": "Lillan_Lamb.jpg", 
        "id": 3, 
        "fun": "", 
        "position": "Treasurer", 
        "email": "lillanlamb@ucla.edu"
    }, 
    {
        "bio": "I am a Computer Engineering student in my third year at UCLA. I have a strong interest in wide range of areas including Embedded Software, Machine Learning, Operating Systems, and FPGA Development.\n\nOther than engineering, I enjoy things like running, skiing, playing saxophone, listening to all kinds of music, and traveling.", 
        "name": "Nicholas Brandis", 
        "src": "Nick_Brandis.jpg", 
        "id": 4, 
        "fun": "I've gone sailing underneath the Golden Gate Bridge.", 
        "position": "Secretary", 
        "email": "niklbrandis@gmail.com"
    }, 
    {
        "bio": "Grad student, electrical engineering, Being a wannabe SoundCloud rapper", 
        "name": "Cody Fan", 
        "src": "Cody_Fan.jpg", 
        "id": 5, 
        "fun": "I peaked in 11th grade when I won the state championship for my grade level in Ohio", 
        "position": "Student Advisor", 
        "email": "codyceilingfan@gmail.com"
    }, 
    {
        "bio": "", 
        "name": "Lime Yao", 
        "src": "Lime_Yao.jpg", 
        "id": 6, 
        "fun": "", 
        "position": "Webmaster", 
        "email": "yaoe888@ucla.edu"
    }, 
    {
        "bio": "", 
        "name": "Aiden Szeto", 
        "src": "Aiden_Szeto.png", 
        "id": 7, 
        "fun": "", 
        "position": "Webmaster", 
        "email": "aszeto35@gmail.com"
    }, 
    {
        "bio": "", 
        "name": "Ramsey Boyce", 
        "src": "Ramsey_Boyce.jpg", 
        "id": 8, 
        "fun": "", 
        "position": "Webmaster", 
        "email": "ramseyboyce@g.ucla.edu"
    }, 
    {
        "bio": "Hi everyone! I'm a third year studying electrical engineering with a computer science tech breadth in the Fast Track program. I like soccer, hiking, digital art, and researching in the QuBiT Lab!", 
        "name": "Courtney Gibbons", 
        "src": "Courtney_Gibbons.jpg", 
        "id": 9, 
        "fun": "I've done research on peeing dogs!", 
        "position": "Publicity", 
        "email": "courtneygibbons@g.ucla.edu"
    }, 
    {
        "bio": "", 
        "name": "Tobias Kim", 
        "src": "Tobias_Kim.jpg", 
        "id": 10, 
        "fun": "", 
        "position": "Historian", 
        "email": "tkim186@g.ucla.edu"
    }, 
    {
        "bio": "", 
        "name": "Joshua Liu", 
        "src": "Joshua_Liu.jpg", 
        "id": 11, 
        "fun": "", 
        "position": "Social", 
        "email": "joshualiu@ucla.edu"
    }, 
    {
        "bio": "I'm a second year, electrical engineering major, and I like to learn K-pop dance choreographies for fun", 
        "name": "Annie Xiang", 
        "src": "Annie_Xiang.jpg", 
        "id": 12, 
        "fun": "I like chocolate cake but prefer vanilla ice cream", 
        "position": "Social", 
        "email": "anniexiang@ucla.edu"
    }, 
    {
        "bio": "", 
        "name": "Koray Mentesoglu", 
        "src": "Koray_Mentesoglu.jpg", 
        "id": 13, 
        "fun": "", 
        "position": "Mentorship", 
        "email": "korayethan@gmail.com"
    }, 
    {
        "bio": "I'm a 2nd year Electrical Engineering major from Belmont, CA. I like to play video games, basketball and have been doing martial arts for 12 years. I have one white floofy Pomeranian named Choco. I love KPOP too especially Twice.", 
        "name": "Eugene Min", 
        "src": "Eugene_Min.jpg", 
        "id": 14, 
        "fun": "I can speak four languages: English, Japanese, Korean, Mandarin", 
        "position": "Mentorship", 
        "email": "eugenemin@g.ucla.edu"
    }, 
    {
        "bio": "", 
        "name": "Thomas Chu", 
        "src": "Thomas_Chu.jpg", 
        "id": 15, 
        "fun": "", 
        "position": "Tutoring", 
        "email": "thomas11950@g.ucla.edu"
    }, 
    {
        "bio": "", 
        "name": "Enoch Huang", 
        "src": "Enoch_Huang.jpg", 
        "id": 16, 
        "fun": "", 
        "position": "Workshops", 
        "email": "eihuang@g.ucla.edu"
    }, 
    {
        "bio": "", 
        "name": "Sudarshan Seshadri", 
        "src": "Sudarshan_Seshadri.jpg", 
        "id": 17, 
        "fun": "", 
        "position": "Workshops", 
        "email": "sseshadri26@g.ucla.edu"
    }, 
    {
        "bio": "", 
        "name": "Elona Khoshaba", 
        "src": "Elona_Khoshaba.jpg", 
        "id": 18, 
        "fun": "", 
        "position": "Membership", 
        "email": "elonakhoshaba@g.ucla.edu"
    }, 
    {
        "bio": "", 
        "name": "Spencer Stice", 
        "src": "Spencer_Stice.jpg", 
        "id": 19, 
        "fun": "", 
        "position": "Membership", 
        "email": "smstice17@gmail.com"
    }, 
    {
        "bio": "", 
        "name": "Rahul Chander", 
        "src": "Rahul_Chander.jpg", 
        "id": 20, 
        "fun": "", 
        "position": "Equity, Diversity and Inclusion ", 
        "email": "rahulc@g.ucla.edu"
    }, 
    {
        "bio": "", 
        "name": "Brandon Lo", 
        "src": "Brandon_Lo.jpg", 
        "id": 21, 
        "fun": "", 
        "position": "Faculty/Alumni", 
        "email": "brandonlo11@ucla.edu"
    }, 
    {
        "bio": "", 
        "name": "Arthur Yang", 
        "src": "Arthur_Yang.jpg", 
        "id": 22, 
        "fun": "", 
        "position": "Faculty/Alumni", 
        "email": "arthury1207@gmail.com"
    }, 
    {
        "bio": "", 
        "name": "Bruce Qu", 
        "src": "Bruce_Qu.jpg", 
        "id": 23, 
        "fun": "", 
        "position": "Outreach", 
        "email": "brucequ@ucla.edu"
    }, 
    {
        "bio": "4th computer engineering", 
        "name": "Alexander Zhang", 
        "src": "Alexander_Zhang.jpg", 
        "id": 24, 
        "fun": "I am a bilingual", 
        "position": "Outreach", 
        "email": "chenggong61@g.ucla.edu"
    }, 
    {
        "bio": "My name is Emir Arda Deger, and I am the Corporate Relations Officer for HKN. I am a third year Electrical Engineering major. My professional interests include electromagnetics, photonics, and circuits. I enjoy sailing, violin, piano, basketball, and tennis. Before UCLA, I played varsity tennis and travelled internationally for competitions.", 
        "name": "Emir Arda Deger", 
        "src": "Emir_Arda_Deger.jpeg", 
        "id": 25, 
        "fun": "In high school, I crossed The Bosphorus Strait each morning because my school was in the European side of Istanbul while I was living in the Asian side. Technically, I was traveling between continents each day as the Bosphorus Strait is a continental boundary between Europe and Asia.", 
        "position": "Corporate Relations", 
        "email": "emirardadeger@ucla.edu"
    }
]
export default OfficerData;
import "./Events.scss";
import { Container } from "react-bootstrap";
import HEADER from "./imgs/eventsmain.jpg";
import HEADER_IMAGE from "../f.jpg";

function Events() {
  return (
    <div className="Events">
      <div id="EventsHeader">
        <img src={HEADER_IMAGE} id="EventsHeaderImage" alt="Royce Hall at night" />
        <Container id="EventsTextOverlay">
          <h1>Events</h1>
        </Container>
      </div>
      <Container>
        <br />
        <div className="EventsBody">
          Subscribe to our{" "}
          <a
            href="https://forms.gle/k3vJ33Z24VGQxVXD9"
            className="Link"
            target="_blank"
            rel="noreferrer"
          >
            newsletter
          </a>{" "}
          for weekly reminders on upcoming events!
        </div>
        <br />
        <iframe
          src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%2382ABAD&ctz=America%2FLos_Angeles&src=aGtuLnVjbGFAZ21haWwuY29t&color=%23D81B60"
          style={{
            border: "solid 5px #2B2828",
            width: "100%",
            height: "600px",
            frameborder: "0",
            scrolling: "no",
          }}
        ></iframe>
      </Container>
    </div>
  );
}
export default Events;

import "./App.scss";
import HomePage from "./components/Pages/HomePage/HomePage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.scss";
function App() {
  return (
    <div className="App" style={{ backgroundColor: "black", color: "white", height: "100vh" }}>
      <HomePage />
    </div>
  );
}

export default App;

import { useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./OfficerAbout.css";
import OfficersAboutData from "./OfficerAboutData";

function OfficerAbout() {
  const name = useParams().name;
  return (
    <Container>
      <br />
      <br />
      <h1>{name.replaceAll("_", " ")}</h1>

      <Row>
        <Col sm={12} md={4}>
          <img
            className="OfficerAboutImage"
            src={"/assets/OfficerImages/" + OfficersAboutData[name].src}
            alt={name}
          />
        </Col>
        <Col sm={12} md={8}>
          <h2>{OfficersAboutData[name].position}</h2>
          {OfficersAboutData[name].bio && <p>{OfficersAboutData[name].bio}</p>}
          {OfficersAboutData[name].fun && (
            <p>
              <b>Fun Fact: </b>
              {OfficersAboutData[name].fun}
            </p>
          )}
          {OfficersAboutData[name].email && (
            <p>
              <b>Email: </b>
              {OfficersAboutData[name].email}
            </p>
          )}
        </Col>
        <Col sm={12} md={{ span: 2, offset: 10 }}>
          <Button href="../officers" style={{ alignSelf: "right" }}>
            Find more officers
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
export default OfficerAbout;

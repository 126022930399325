import DISCORD from "./imgs/discord.png";
import LINKEDIN from "./imgs/linkedin.png";
import FACEBOOK from "./imgs/facebook.png";
import YOUTUBE from "./imgs/youtube.png";

import "./Footer.css";

function Footer() {
  return (
    <div className="Footer">
      <p>FIND US ON</p>
      <div id="SocialHandlesWrapper">
        <div className="SocialHandles">
          <a href="https://discord.com/invite/VeTvr2R">
            <img src={DISCORD} className="Image" alt="discord icon" />
          </a>
          <a href="https://www.facebook.com/hkn.ucla">
            <img src={FACEBOOK} className="Image" alt="facebook icon" />
          </a>
          <a href="https://www.linkedin.com/company/eta-kappa-nu-iota-gamma-chapter-ucla/">
            <img src={LINKEDIN} className="Image" alt="linkedin icon" />
          </a>
          <a href="https://www.youtube.com/channel/UCxAaykMPevL8YH3LCEdx6NA">
            <img src={YOUTUBE} className="Image" alt="youtube icon" />
          </a>
        </div>
      </div>
    </div>
  );
}
export default Footer;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#EventsTextOverlay {
  margin-top: -150px;
  height: 150px;
  top: 0px;
}
#EventsTextOverlay h1 {
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif !important;
  -webkit-text-decoration: bold;
          text-decoration: bold;
  font-weight: bold;
  padding-bottom: 0.3rem;
}

#EventsHeaderImage {
  width: 100%;
  height: 300px;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/Events/Events.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAEA;ECcE,kBAAA;EACA,aAAA;EACA,QAAA;ADdF;ACeE;EAXA,eAAA;EACA,oDAAA;EACA,6BAAA;UAAA,qBAAA;EACA,iBAAA;EACA,sBAAA;ADDF;;AALA;ECmBE,WAAA;EACA,aAAA;EACA,iBAAA;ADVF","sourcesContent":["@import \"../../../mixins.scss\";\n\n#EventsTextOverlay {\n  @include title-overlay;\n}\n\n#EventsHeaderImage {\n  @include image-banner;\n}\n","@mixin small-heading-font {\n  font-size: 25px;\n  font-family: Arial, Helvetica, sans-serif !important;\n  text-decoration: bold;\n  font-weight: bold;\n}\n\n@mixin heading-font {\n  font-size: 40px;\n  font-family: Arial, Helvetica, sans-serif !important;\n  text-decoration: bold;\n  font-weight: bold;\n  padding-bottom: 0.3rem;\n}\n\n@mixin title-overlay {\n  margin-top: -150px;\n  height: 150px;\n  top: 0px;\n  h1 {\n    @include heading-font;\n  }\n}\n\n@mixin image-banner {\n  width: 100%;\n  height: 300px;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Carousel, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./Landing.scss";
function Landing() {
  const images = [...Array(7).keys()];

  return (
    <div id="landingPage">
      <div id="landing-overlay">
        <h4 id="landing-title-small">ETA KAPPA NU</h4>
        <h1 id="landing-title-large">
          UCLA's Electrical and Computer Engineering Honor Society
        </h1>
        <a href="/about">
          <Button>Learn more</Button>
        </a>
      </div>
      <div id="landing-carousel">
        <Carousel interval={3000}>
          {images.map((imgNum, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                style={{
                  objectFit: "cover",
                  height: "100vh",
                }}
                src={"/assets/LandingCarousel/darkened/" + imgNum + ".jpg"}
                alt={"gallery image" + imgNum}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
export default Landing;

import MAINICON from "./imgs/image.png";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import "./Header.css";

function Header() {
  return (
    <Navbar bg="primary" variant="dark" expand="md" style={{ zIndex: "10" }}>
      <Container>
        <Navbar.Brand href="/">
          <img
            src={MAINICON}
            width="200px"
            className="d-inline-block align-top"
            alt="hkn logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto" variant="pills">
            <Nav.Link className="header-nav-link" href="/about">
              About
            </Nav.Link>
            <Nav.Link className="header-nav-link" href="/events">
              Events
            </Nav.Link>
            <Nav.Link className="header-nav-link" href="/officers">
              Officers
            </Nav.Link>
            <Nav.Link className="header-nav-link" href="/tutoring">
              Tutoring
            </Nav.Link>
            <Nav.Link href="/login">
              <Button id="login-button" variant="light">
                Login
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default Header;

import { Form, Container, Row, Col, Button } from "react-bootstrap";
import "./Login.scss";
import HEADER from "./imgs/login.jpg";

function Login() {
  return (
    <div id="Login">
      <div id="LoginHeader">
        <img src={HEADER} id="LoginHeaderImage" alt="Bruin Bear" />
      </div>
      <Container id="LoginTextOverlay">
        <div>Login</div>
      </Container>
      <Container>
        <div style={{ color: "navy" }}>
          <h1>Website under construction</h1>
        </div>
        {/* <Form id="LoginForm">
          <Row>
            <Col base={12} md={6}>
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label className="LoginFormLabel">Username</Form.Label>
                <Form.Control
                  size="lg"
                  type="email"
                  placeholder="Enter username"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col base={12} md={6}>
              <Form.Group className="mb-3" controlId="formGroupPassword">
                <Form.Label className="LoginFormLabel">Password</Form.Label>
                <Form.Control
                  size="lg"
                  type="password"
                  placeholder="Password"
                />
              </Form.Group>
            </Col>
            <Row>
            <Col base={12} md={{offset:4, span: 2}}>
              <Button style={{width:"100%"}}>
                Login
              </Button>
            </Col>
            </Row>
          </Row>
        </Form> */}
      </Container>
    </div>
  );
}
export default Login;

import { Card } from "react-bootstrap";
import "./OfficerCard.css";

function OfficerCard(props) {
  return (
    <Card
      className="officerCard"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
        height: "90%",
        padding: "2%",
      }}
      onClick={() => {
        let newUrl =
          window.location.origin +
          "/officers/" +
          props.name.replaceAll(" ", "_");
        window.location.href = newUrl;
      }}
    >
      <Card.Img
        variant="top"
        src={props.imgPath}
        style={{ width: "100%", aspectRatio: 1 / 1, borderRadius: "50%" }}
      />
      <div className="officerText">{props.name}</div>
      <div className="officerText">{props.position}</div>
    </Card>
  );
}

export default OfficerCard;

import GROUP from "./imgs/group.png";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./AboutUs.scss";
import Officers from "./imgs/officers.jpg";
import Tutoring from "./imgs/tutoring.jpeg";
import Login from "./imgs/login.jpg";
import Events from "./imgs/events.jpg";

function AboutUs() {
  let pageDesc = [
    ["Tutoring", "Peer-to-peer tutoring M-F", Tutoring],
    ["Events", "View our upcoming events", Events],
    ["Portal", "Sign in to view more", Login],
    ["Officers", "Contact our officers", Officers],
  ];
  return (
    <Container className="AboutUs">
      <br />
      <Row xs={1} md={2}>
        <Col>
          <div>
            <h1 id="AboutUsTitle">About HKN</h1>
            <div className="AboutText">
              HKN stands for Eta Kappa Nu. We are an international Honor Society
              for Electrical and Computer Engineering. HKN at UCLA aims to
              enable fellow engineering students to excel in both personal and
              professional settings. <br />
              <br />
              By organizing peer-to-peer tutoring, various workshops, review
              sessions, and career fairs, HKN wants to support and connect our
              engineering students with the best resources to enable them to
              succeed in academics and to explore their career options. Some of
              our most popular events include course review sessions, company
              info sessions, resume reviews, and professor lunches. <br />
              <br />
              The members of HKN at UCLA are the top ¼ of the Junior class or
              top ⅓ of the Senior class of undergraduate Electrical and Computer
              Engineering students. We are an invite-only organization, but if
              you would like to keep up-to-date with HKN, feel free to sign up
              for our newsletter{" "}
              <b>
                <a
                  href="https://forms.gle/k3vJ33Z24VGQxVXD9"
                  className="Link"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
              </b>
              .
            </div>
          </div>
          <br />
        </Col>
        <Col>
          <img className="groupShot" src={GROUP} alt="Group photo" />
        </Col>
      </Row>
      <Row
        xs={1}
        md={4}
        className="g-4"
        style={{ marginTop: "5%", marginBottom: "5%" }}
      >
        {Array.from({ length: 4 }).map((_, idx) => (
          <Col key={idx}>
            <Card
              className="aboutCard"
              bg="primary"
              style={{ borderRadius: "15px" }}
              onClick={() => {
                let newUrl =
                  window.location.href +
                  "/../" +
                  pageDesc[idx][0].toLowerCase();
                console.log(newUrl);
                window.location.href = newUrl;
              }}
            >
              <Card.Img
                style={{
                  height: "150px",
                  objectFit: "cover",
                  borderRadius: "15px",
                }}
                variant="bottom"
                src={pageDesc[idx][2]}
              />
              {/* <Card.ImgOverlay> */}
              <Card.Body>
                <Card.Title>{pageDesc[idx][0]}</Card.Title>
                <Card.Text>{pageDesc[idx][1]}</Card.Text>
              </Card.Body>
              {/* </Card.ImgOverlay> */}
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
export default AboutUs;

import React, { useState, useEffect } from "react";
import "./Tutoring.scss";
import TutoringCalendar from "./TutoringCalendar";
import subjectData from "./data/subject_data";
import tutorData from "./data/tutor_data";
import { Row, Col } from "react-bootstrap";

function TutorSelect() {
  const [selectedIntervals, setSelectedIntervals] = useState(tutorData);

  const [subject, setSubject] = useState("EC ENGR");
  const [courseNum, setCourseNum] = useState(2);

  const handleSubjectChange = (event) => {
    const newSubject = event.target.value;
    setSubject(newSubject);
    setCourseNum(Object.keys(subjectData[newSubject])[0]);
  };

  const handleNumChange = (event) => {
    setCourseNum(event.target.value);
  };

  useEffect(() => {
    // get uid's corresponding to that topic
    const uids = subjectData[subject][courseNum];
    // send highlighted uids to calendar
    const changedIntervals = selectedIntervals.map((x) => {
      x.highlighted = uids;
      return x;
    });
    setSelectedIntervals(changedIntervals); //update the state
  }, [subject, courseNum]);

  const makeOpts = (arr, numerical) => {
    if (numerical) {
      arr.sort((a, b) => a.replace(/[^0-9]/g, "") - b.replace(/[^0-9]/g, ""));
    } else {
      arr.sort();
    }
    const opts = [];
    for (const opt of arr) {
      opts.push(<option key={opt} value={opt}>{opt}</option>);
    }
    return opts;
  };

  return (
    <div>
      <TutoringCalendar
        className="TutoringCalendar"
        selectedIntervals={selectedIntervals}
      />
    </div>
  );
}

export default TutorSelect;

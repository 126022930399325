import moment from "moment";

const M = 1;
const T = 2;
const W = 3;
const R = 4;
const F = 5;

const createMoment = (props) => {
    const monday = moment().startOf("isoWeek");
    const adjust = props.d - 1;
    const res = monday
      .set("hour", props.h)
      .set("minute", props.m)
      .add(adjust, "d");
    return res;
  };

const tutors = [
    "Georgios Spanodimos","Nathan Chen, Darren Chin","Sebastian Barretto, Jessie Chan","Nathan Leobandung","Evren Shevket, Patrick Mulligan",
    "Edward Liu","Nathan Chen, Simba Chen, Georgios Spanodimos, Darren Chin","Sebastian Barretto, Jessie Chan","Christian Giron-Michel, Nathan Leobandung","Patrick Mulligan",
    "Eugene Min, Edward Liu","Taha El-Halabi","Felipe Andrade","Ethan Lai, Christian Giron-Michel, Jaz Reyes","Sijie Peng",
    "Lydia Huang, Rachel Yen, Joshua Burpee","Iris Shen, Taha El-Halabi, Howard Shu","Felipe Andrade","Jaz Reyes","Sijie Peng",
    "Shreyas Kaasyap, Joshua Burpee","Iris Shen, Howard Shu","Enoch Huang","Francisco Cardena Beltran, Cameron Leary","Yihan Wang",
    "Shreyas Kaasyap","Thomas Chu, Cameron Leary","","Arthur Yang, Francisco Cardena Beltran","Yihan Wang"
];
const tutorData = [
    {
      uid: 1,
      start: createMoment({ h: 10, m: 0, d: M }),
      end: createMoment({ h: 11, m: 0, d: M }),
      value: tutors[0],
      highlighted: [],
    },
    {
      uid: 2,
      start: createMoment({ h: 10, m: 0, d: T }),
      end: createMoment({ h: 11, m: 0, d: T }),
      value: tutors[1],
      highlighted: [],
    },
    {
      uid: 3,
      start: createMoment({ h: 10, m: 0, d: W }),
      end: createMoment({ h: 11, m: 0, d: W }),
      value: tutors[2],
      highlighted: [],
    },
    {
      uid: 4,
      start: createMoment({ h: 10, m: 0, d: R }),
      end: createMoment({ h: 11, m: 0, d: R }),
      value: tutors[3],
      highlighted: [],
    },
    {
      uid: 5,
      start: createMoment({ h: 10, m: 0, d: F }),
      end: createMoment({ h: 11, m: 0, d: F }),
      value: tutors[4],
      highlighted: [],
    },
    {
      uid: 6,
      start: createMoment({ h: 11, m: 0, d: M }),
      end: createMoment({ h: 12, m: 0, d: M }),
      value: tutors[5],
      highlighted: [],
    },
    {
      uid: 7,
      start: createMoment({ h: 11, m: 0, d: T }),
      end: createMoment({ h: 12, m: 0, d: T }),
      value: tutors[6],
      highlighted: [],
    },
    {
      uid: 8,
      start: createMoment({ h: 11, m: 0, d: W }),
      end: createMoment({ h: 12, m: 0, d: W }),
      value: tutors[7],
      highlighted: [],
    },
    {
      uid: 9,
      start: createMoment({ h: 11, m: 0, d: R }),
      end: createMoment({ h: 12, m: 0, d: R }),
      value: tutors[8],
      highlighted: [],
    },
    {
      uid: 10,
      start: createMoment({ h: 11, m: 0, d: F }),
      end: createMoment({ h: 12, m: 0, d: F }),
      value: tutors[9],
      highlighted: [],
    },
    {
      uid: 11,
      start: createMoment({ h: 12, m: 0, d: M }),
      end: createMoment({ h: 13, m: 0, d: M }),
      value: tutors[10],
      highlighted: [],
    },
    {
      uid: 12,
      start: createMoment({ h: 12, m: 0, d: T }),
      end: createMoment({ h: 13, m: 0, d: T }),
      value: tutors[11],
      highlighted: [],
    },
    {
      uid: 13,
      start: createMoment({ h: 12, m: 0, d: W }),
      end: createMoment({ h: 13, m: 0, d: W }),
      value: tutors[12],
      highlighted: [],
    },
    {
      uid: 14,
      start: createMoment({ h: 12, m: 0, d: R }),
      end: createMoment({ h: 13, m: 0, d: R }),
      value: tutors[13],
      highlighted: [],
    },
    {
      uid: 15,
      start: createMoment({ h: 12, m: 0, d: F }),
      end: createMoment({ h: 13, m: 0, d: F }),
      value: tutors[14],
      highlighted: [],
    },
    {
      uid: 16,
      start: createMoment({ h: 13, m: 0, d: M }),
      end: createMoment({ h: 14, m: 0, d: M }),
      value: tutors[15],
      highlighted: [],
    },
    {
      uid: 17,
      start: createMoment({ h: 13, m: 0, d: T }),
      end: createMoment({ h: 14, m: 0, d: T }),
      value: tutors[16],
      highlighted: [],
    },
    {
      uid: 18,
      start: createMoment({ h: 13, m: 0, d: W }),
      end: createMoment({ h: 14, m: 0, d: W }),
      value: tutors[17],
      highlighted: [],
    },
    {
      uid: 19,
      start: createMoment({ h: 13, m: 0, d: R }),
      end: createMoment({ h: 14, m: 0, d: R }),
      value: tutors[18],
      highlighted: [],
    },
    {
      uid: 20,
      start: createMoment({ h: 13, m: 0, d: F }),
      end: createMoment({ h: 14, m: 0, d: F }),
      value: tutors[19],
      highlighted: [],
    },
    {
      uid: 21,
      start: createMoment({ h: 14, m: 0, d: M }),
      end: createMoment({ h: 15, m: 0, d: M }),
      value: tutors[20],
      highlighted: [],
    },
    {
      uid: 22,
      start: createMoment({ h: 14, m: 0, d: T }),
      end: createMoment({ h: 15, m: 0, d: T }),
      value: tutors[21],
      highlighted: [],
    },
    {
      uid: 23,
      start: createMoment({ h: 14, m: 0, d: W }),
      end: createMoment({ h: 15, m: 0, d: W }),
      value: tutors[22],
      highlighted: [],
    },
    {
      uid: 24,
      start: createMoment({ h: 14, m: 0, d: R }),
      end: createMoment({ h: 15, m: 0, d: R }),
      value: tutors[23],
      highlighted: [],
    },
    {
      uid: 25,
      start: createMoment({ h: 14, m: 0, d: F }),
      end: createMoment({ h: 15, m: 0, d: F }),
      value: tutors[24],
      highlighted: [],
    },

    {
        uid: 26,
        start: createMoment({ h: 15, m: 0, d: M }),
        end: createMoment({ h: 16, m: 0, d: M }),
        value: tutors[25],
        highlighted: [],
      },
      {
        uid: 27,
        start: createMoment({ h: 15, m: 0, d: T }),
        end: createMoment({ h: 16, m: 0, d: T }),
        value: tutors[26],
        highlighted: [],
      },
      {
        uid: 28,
        start: createMoment({ h: 15, m: 0, d: W }),
        end: createMoment({ h: 16, m: 0, d: W }),
        value: tutors[27],
        highlighted: [],
      },
      {
        uid: 29,
        start: createMoment({ h: 15, m: 0, d: R }),
        end: createMoment({ h: 16, m: 0, d: R }),
        value: tutors[28],
        highlighted: [],
      },
      {
        uid: 30,
        start: createMoment({ h: 15, m: 0, d: F }),
        end: createMoment({ h: 16, m: 0, d: F }),
        value: tutors[29],
        highlighted: [],
      },

      {
        uid: 31,
        start: createMoment({ h: 16, m: 0, d: M }),
        end: createMoment({ h: 17, m: 0, d: M }),
        value: tutors[30],
        highlighted: [],
      },
      {
        uid: 32,
        start: createMoment({ h: 16, m: 0, d: T }),
        end: createMoment({ h: 17, m: 0, d: T }),
        value: tutors[31],
        highlighted: [],
      },
      {
        uid: 33,
        start: createMoment({ h: 16, m: 0, d: W }),
        end: createMoment({ h: 17, m: 0, d: W }),
        value: tutors[32],
        highlighted: [],
      },
      {
        uid: 34,
        start: createMoment({ h: 16, m: 0, d: R }),
        end: createMoment({ h: 17, m: 0, d: R }),
        value: tutors[33],
        highlighted: [],
      },
      {
        uid: 35,
        start: createMoment({ h: 16, m: 0, d: F }),
        end: createMoment({ h: 17, m: 0, d: F }),
        value: tutors[34],
        highlighted: [],
      },

    {
      uid: 36,
      start: createMoment({ h: 17, m: 0, d: M }),
      end: createMoment({ h: 18, m: 0, d: M }),
      value: tutors[35],
      highlighted: [],
    },
    {
      uid: 37,
      start: createMoment({ h: 17, m: 0, d: T }),
      end: createMoment({ h: 18, m: 0, d: T }),
      value: tutors[36],
      highlighted: [],
    },
    {
      uid: 38,
      start: createMoment({ h: 17, m: 0, d: W }),
      end: createMoment({ h: 18, m: 0, d: W }),
      value: tutors[37],
      highlighted: [],
    },
    {
      uid: 39,
      start: createMoment({ h: 17, m: 0, d: R }),
      end: createMoment({ h: 18, m: 0, d: R }),
      value: tutors[38],
      highlighted: [],
    },
    {
      uid: 40,
      start: createMoment({ h: 17, m: 0, d: F }),
      end: createMoment({ h: 18, m: 0, d: F }),
      value: tutors[39],
      highlighted: [],
    },
    {
      uid: 41,
      start: createMoment({ h: 18, m: 0, d: M }),
      end: createMoment({ h: 19, m: 0, d: M }),
      value: tutors[40],
      highlighted: [],
    },
    {
      uid: 42,
      start: createMoment({ h: 18, m: 0, d: T }),
      end: createMoment({ h: 19, m: 0, d: T }),
      value: tutors[41],
      highlighted: [],
    },
    {
      uid: 43,
      start: createMoment({ h: 18, m: 0, d: W }),
      end: createMoment({ h: 19, m: 0, d: W }),
      value: tutors[42],
      highlighted: [],
    },
    {
      uid: 44,
      start: createMoment({ h: 18, m: 0, d: R }),
      end: createMoment({ h: 19, m: 0, d: R }),
      value: tutors[43],
      highlighted: [],
    },
    {
      uid: 45,
      start: createMoment({ h: 18, m: 0, d: F }),
      end: createMoment({ h: 19, m: 0, d: F }),
      value: tutors[44],
      highlighted: [],
    },
  ];
export default tutorData;

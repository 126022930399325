import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  start: PropTypes.object.isRequired,
  end: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
};

function TutoringEvent(props) {
  return (
    <div
      className={
        props.highlighted.includes(props.uid) ? "event_highlighted" : "event"
      }
    >
      {props.value}
    </div>
  );
}

TutoringEvent.propTypes = propTypes;
export default TutoringEvent;

import AboutUs from "../AboutUs/AboutUs";
import Landing from "../Landing/Landing";
import Officers from "../Officers/Officers";
import OfficerAbout from "../Officers/OfficerAbout/OfficerAbout";
import Tutoring from "../Tutoring/Tutoring";
import Login from "../Login/Login";
import Portal from "../MemberPortal/MemberPortal";
// import TestBank from "../TestBank/TestBank";
// import InducteePortal from "../InducteePortal/InducteePortal";
import Events from "../Events/Events";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./HomePage.css";

// import ScrollToTop from "./ScrollToTop";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

function HomePage() {
  return (
    <div style={{ height: "100%" }}>
      <Header />
      <div style={{ minHeight: "80vh" }}>
        <Router>
          <Switch>
            <Route path="/officers/:name" component={OfficerAbout} />
            <Route path="/officers" component={Officers} />
            <Route path="/tutoring" component={Tutoring} />
            {/* <Route path="/testBank" component={TestBank} />
              <Route path="/inductee" component={InducteePortal} /> */}
            {/* <Route path="/portal" component={Portal} /> */}
            <Route path="/events" component={Events} />
            <Route path={["/login", "/portal"]} component={Login} />
            <Route path="/about" component={AboutUs} />
            <Route path="/" component={Landing} />
          </Switch>
        </Router>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;

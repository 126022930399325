// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aboutCard:hover {
  transform: scale(1.1);
}

#AboutUsTitle {
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif !important;
  -webkit-text-decoration: bold;
          text-decoration: bold;
  font-weight: bold;
  padding-bottom: 0.3rem;
}

.groupShot {
  object-fit: contain;
  width: 100%;
  border-radius: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/AboutUs/AboutUs.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;AADF;;AAIA;ECEE,eAAA;EACA,oDAAA;EACA,6BAAA;UAAA,qBAAA;EACA,iBAAA;EACA,sBAAA;ADFF;;AAAA;EACE,mBAAA;EACA,WAAA;EACA,mBAAA;AAGF","sourcesContent":["@import \"../../../mixins.scss\";\n\n.aboutCard:hover {\n  transform: scale(1.1);\n}\n\n#AboutUsTitle {\n  @include heading-font;\n}\n\n.groupShot {\n  object-fit: contain;\n  width: 100%;\n  border-radius: 15px;\n}","@mixin small-heading-font {\n  font-size: 25px;\n  font-family: Arial, Helvetica, sans-serif !important;\n  text-decoration: bold;\n  font-weight: bold;\n}\n\n@mixin heading-font {\n  font-size: 40px;\n  font-family: Arial, Helvetica, sans-serif !important;\n  text-decoration: bold;\n  font-weight: bold;\n  padding-bottom: 0.3rem;\n}\n\n@mixin title-overlay {\n  margin-top: -150px;\n  height: 150px;\n  top: 0px;\n  h1 {\n    @include heading-font;\n  }\n}\n\n@mixin image-banner {\n  width: 100%;\n  height: 300px;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
  color: white;
  font-size: 20px;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: black;
  width: 100%;
  align-content: center;
  position: static;
  /* bottom:0%; */
}
#SocialHandlesWrapper {
  width: 100%;
  display: flex;
}
.SocialHandles {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-self: center;
}
.Image {
  width: 32px;
  margin: 5px;
  border-radius: 30%;
  transition: all 0.2s ease-in-out;
}
.Image:hover {
  transform: scale(1.1);
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,WAAW;EACX,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,gCAAgC;AAClC;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".Footer {\n  color: white;\n  font-size: 20px;\n  text-align: center;\n  padding-top: 1%;\n  padding-bottom: 1%;\n  background-color: black;\n  width: 100%;\n  align-content: center;\n  position: static;\n  /* bottom:0%; */\n}\n#SocialHandlesWrapper {\n  width: 100%;\n  display: flex;\n}\n.SocialHandles {\n  margin-left: auto;\n  margin-right: auto;\n  display: flex;\n  align-self: center;\n}\n.Image {\n  width: 32px;\n  margin: 5px;\n  border-radius: 30%;\n  transition: all 0.2s ease-in-out;\n}\n.Image:hover {\n  transform: scale(1.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

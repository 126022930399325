// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#LoginTextOverlay {
  font-size: 40px;
  font-family: Arial, Helvetica, sans-serif !important;
  -webkit-text-decoration: bold;
          text-decoration: bold;
  font-weight: bold;
  padding-bottom: 0.3rem;
  margin-top: -90vh;
  height: 150px;
}

#LoginHeaderImage {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.LoginFormLabel {
  font-size: 1.5em;
  color: #0f2a5f;
}`, "",{"version":3,"sources":["webpack://./src/components/Pages/Login/Login.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AACA;ECOE,eAAA;EACA,oDAAA;EACA,6BAAA;UAAA,qBAAA;EACA,iBAAA;EACA,sBAAA;EDTA,iBAAA;EACA,aAAA;AAIF;;AAFA;EACE,WAAA;EACA,aAAA;EACA,iBAAA;AAKF;;AAHA;EACE,gBAAA;EACA,cAAA;AAMF","sourcesContent":["@import \"../../../mixins.scss\";\n#LoginTextOverlay {\n  @include heading-font;\n  margin-top: -90vh;\n  height: 150px;\n}\n#LoginHeaderImage {\n  width: 100%;\n  height: 100vh;\n  object-fit: cover;\n}\n.LoginFormLabel {\n  font-size: 1.5em;\n  color: #0f2a5f;\n}\n","@mixin small-heading-font {\n  font-size: 25px;\n  font-family: Arial, Helvetica, sans-serif !important;\n  text-decoration: bold;\n  font-weight: bold;\n}\n\n@mixin heading-font {\n  font-size: 40px;\n  font-family: Arial, Helvetica, sans-serif !important;\n  text-decoration: bold;\n  font-weight: bold;\n  padding-bottom: 0.3rem;\n}\n\n@mixin title-overlay {\n  margin-top: -150px;\n  height: 150px;\n  top: 0px;\n  h1 {\n    @include heading-font;\n  }\n}\n\n@mixin image-banner {\n  width: 100%;\n  height: 300px;\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

const subjectData = {
  MATH: {
    "31A": [
      /*1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 29, 30, 31, 33, 34, 35,*/
    ],
    "31B": [],
    "32A": [],
    "32B": [],
    "33A": [],
    "33B": [],
    "61": [],
  },
  PHYSICS: {
    "1A": [],
    "1B": [],
    "1C": [],
  },
  "COM SCI": {
    "31": [],
    "32": [],
    "33": [],
    "35L": [],
    "111": [],
    "143": [],
    "M152A": [],
    "180": [],
    "M146": [],
    "M152B": [],
    "131": [],
  },
  "EC ENGR": {
    "3": [],
    "2": [],
    "10": [],
    "11L": [],
    "M16": [],
    "101A": [],
    "101B": [],
    "102": [],
    "110": [],
    "111L": [],
    "113": [],
    "115A": [],
    "131A": [],
    "141": [],
    "100": [],
    "115B": [],
    "133A": [],
    "170A": [],
    "114": [],
    "121B": [],
    "C143A": [],
  },
  PROGRAMMING: {
    "PYTHON": [],
    "MATLAB": [],
  },
};
export default subjectData;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-nav-link:hover {
  background-color: navy !important;
}
.header-nav-link {
  align-self: center;
}
#login-button:hover {
  transform: scale(1.1);
}
#login-button {
  background-color: white;
}
#basic-navbar-nav {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;AACA;EACE,kBAAkB;AACpB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".header-nav-link:hover {\n  background-color: navy !important;\n}\n.header-nav-link {\n  align-self: center;\n}\n#login-button:hover {\n  transform: scale(1.1);\n}\n#login-button {\n  background-color: white;\n}\n#basic-navbar-nav {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import HEADER from "./tutoring.jpeg";
import "./Tutoring.scss";
import TutorSelect from "./TutorSelect";
import { Container } from "react-bootstrap";
import HEADER_IMAGE from "../f.jpg";

function Tutoring() {
  return (
    <div className="Tutoring">
      <div className="TutoringHeader">
        <img src={HEADER_IMAGE} id="TutoringHeaderImage" alt="tutoring room" />
        <Container id="TutoringTextOverlay">
          <h1>Tutoring</h1>
        </Container>
      </div>
      <Container className="AboutTutoring">
        <br />
        <h4>What is HKN Tutoring?</h4>
        <div className="TutoringText" style={{ width: "90%" }}>
          As an organization centered around academics, HKN at UCLA provides
          one-on-one tutoring from our officers and members for lower division
          math and physics, as well as most upper-division ECE courses. If you
          have questions about a class in ECE, our members will gladly assist
          you. Additionally, if you need any career advice, we also have
          students with internship experiences who can help you look over your
          resume and answer any questions.
          <br />
          <br />
          Peer-to-peer tutoring is offered from Monday to Friday 10AM – 4PM
          every quarter, Weeks 3 – 9. All tutoring is walk-in based with no
          reservations required. Feel free to pop in and talk to officers, or
          leave questions on the channels related to your class!
          <br />
          <br />
          <h4>Hours</h4>
          In-person: 10am-4pm @67-127 in Engineering 4 <br />
          Online: 
          <b>
            <a
              className="Link"
              href="https://discord.gg/VeTvr2R"
              target="_blank"
              rel="noreferrer"
            >
              Our discord server
            </a>
          </b>
          <br />
          <br />
        </div>
      </Container>

      <Container className="FindTutor">
        <h4>Find a Tutor – Spring 2024</h4>
        <div className="TutoringForm">
          <TutorSelect />
        </div>   
      </Container>
    </div>
  );
}

export default Tutoring;
